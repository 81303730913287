/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

//bootstrap
import "bootstrap/dist/css/bootstrap.css";

// slick-theme
import "./src/components/common/slick.min.css";

import "./src/components/homepagenew/common.scss";
import "./src/components/common/fonts.scss";

import './src/styles/global.css';

export function shouldUpdateScroll() { return false; }
